import { html } from 'htm/preact';

import { renderComponent } from '@app/dom';
import { Modal, useModal } from '@app/widgets/components/Modal';
import { Link } from '@app/widgets/components/Link';
import { i18n } from '@app/i18n';

const OfferCancelApplicationModal = ({ parentElement }) => {
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const title = i18n.t('offer.cancel.confirm.title');
  const onCloseButtonClick = () => setIsOpen(false);

  return html`<${Modal} title=${title} isOpen=${isOpen} onRequestClose=${onCloseButtonClick}>
    <div className="u-padding--l">
      <p className="c-typography c-typography--paragraph u-margin-vertical--l">
        ${i18n.t('offer.cancel.confirm.description')}
      </p>
      <div className="o-layout o-layout--justify-end u-margin-top--xl">
        <div className="o-layout__item u-padding-horizontal--xs">
          <span className="c-button c-button--secondary" onClick="${onCloseButtonClick}">
            ${i18n.t('offer.cancel.confirm.cancel')}
          </span>
        </div>
        <div className="o-layout__item u-padding-horizontal--xs">
          <${Link} href="${triggerData.actionLink}" method="get" className="c-button c-button--caution"
          data-analytics-event="worker_did_cancel_offer_application">
            ${i18n.t('offer.cancel.confirm.confirm')}
          </${Link}>
        </div>
      </div>
    </div>
  </${Modal}>`;
};

renderComponent('#offer-cancel-application-modal', OfferCancelApplicationModal);
