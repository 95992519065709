import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="checkbox-select"
export class CheckboxSelectController extends Controller {
  static targets = ['input', 'option'];
  static scrollFrameTimeout = 20;
  static pauseFrameTimeout = 1000;

  #selectedOptions = new Set();
  #isAnimated = false;

  connect() {
    this.optionTargets.forEach((target) => {
      this.#handleOption(target, target.querySelector('input'));
    });
  }

  onChange(event) {
    this.#handleOption(event.target.parentElement, event.target);
  }

  startAnimation() {
    if (this.inputTarget.scrollWidth <= this.inputTarget.clientWidth) {
      return;
    }

    this.inputTarget.classList.add('text-clip');
    this.#isAnimated = true;

    const minScroll = 0;
    const maxScroll = this.inputTarget.scrollWidth - this.inputTarget.clientWidth;

    const renderFrame = (reversed) => {
      this.inputTarget.scrollLeft += reversed ? -1 : 1;

      let nextStepReversed = reversed;
      let timeout = CheckboxSelectController.scrollFrameTimeout;

      if (this.inputTarget.scrollLeft <= minScroll) {
        nextStepReversed = false;
        timeout = CheckboxSelectController.pauseFrameTimeout;
      } else if (this.inputTarget.scrollLeft >= maxScroll) {
        nextStepReversed = true;
        timeout = CheckboxSelectController.pauseFrameTimeout;
      }

      if (this.#isAnimated) {
        setTimeout(() => renderFrame(nextStepReversed), timeout);
      } else {
        this.inputTarget.scrollLeft = 0;
        this.inputTarget.classList.remove('text-clip');
      }
    };

    renderFrame(false);
  }

  stopAnimation() {
    this.#isAnimated = false;
  }

  #handleOption(optionElement, inputElement) {
    inputElement.checked ? this.#selectedOptions.add(optionElement) : this.#selectedOptions.delete(optionElement);
    this.#updateUI();
  }

  #updateUI() {
    const texts = Array.from(this.#selectedOptions.values()).map(
      (optionElement) => optionElement.textContent.trim(),
    );

    this.inputTarget.value = texts.join(', ');
  }
}
