import { tie } from '@app/dom';

tie.bind('form[data-ensure-save]', (element) => {
  let touched = false;

  const blockPageUnload = (event) => {
    if (!touched) {
      return;
    }

    event.preventDefault();
    event.returnValue = '';
    return '';
  };

  const removeBlockPageUnload = () => {
    window.removeEventListener('beforeunload', blockPageUnload);
  };

  const onChange = () => {
    touched = true;
  };

  window.addEventListener('beforeunload', blockPageUnload);
  element.addEventListener('change', onChange);
  element.addEventListener('submit', removeBlockPageUnload);

  return (removedElement) => {
    removedElement.removeEventListener('submit', removeBlockPageUnload);
    removedElement.removeEventListener('change', onChange);
    removeBlockPageUnload();
  };
});
