import { useRef } from 'preact/hooks';
import { html } from 'htm/preact';

import { classNames } from '@app/dom';

export const Link = ({ method, children, ...props }) => {
  const ref = useRef();
  const noFollow = Boolean(method) && !/^get$/i.test(method);

  return html`<a
    ref=${ref}
    rel=${classNames(noFollow && 'nofollow')}
    data-method=${method}
    ...${props}
  >
    ${children}
  </a>`;
};
