import { html } from 'htm/preact';

import { renderComponent } from '@app/dom';
import { Modal, useModal } from '@app/widgets/components/Modal';
import { Form } from '@app/widgets/components/Form';
import { i18n } from '@app/i18n';

const RejectHoursModal = ({ parentElement }) => {
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const title = i18n.t('engagement.log_hours.reject_hours_modal.title');
  const onCloseButtonClick = () => setIsOpen(false);

  return html`<${Modal} title=${title} isOpen=${isOpen} onRequestClose=${onCloseButtonClick}>
    <div className="u-padding--l">
      <p className="c-typography c-typography--paragraph u-margin-top--s u-margin-bottom--xl">
        ${i18n.t('engagement.log_hours.reject_hours_modal.confirm')}
      </p>
      <${Form} action=${triggerData.actionLink}>
        <label className="c-label">
          <textarea name="comment" placeholder=${i18n.t(
            'engagement.log_hours.reject_hours_modal.comment_placeholder',
          )} rows="4" className="c-input u-size--match-parent-width" data-type="textarea" />
          <span className="c-label__text">${i18n.t('engagement.log_hours.reject_hours_modal.comment_placeholder')}</span>
        </label>
        <div className="o-layout o-layout--justify-end u-margin-top--xl">
          <div className="o-layout__item u-padding-horizontal--xs">
            <span className="c-button c-button--secondary" onClick=${onCloseButtonClick}>
              ${i18n.t('engagement.log_hours.reject_hours_modal.cancel')}
            </span>
          </div>
          <div className="o-layout__item u-padding-horizontal--xs">
            <input type="submit" className="c-button c-button--caution" value=${i18n.t(
              'engagement.log_hours.reject_hours_modal.reject',
            )} data-analytics-event="employer_rejects_hours" />
          </div>
        </div>
      </${Form}>
    </div>
  </${Modal}>`;
};

renderComponent('#reject-hours-modal', RejectHoursModal);
