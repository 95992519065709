import { tie, onLoad } from '@app/dom';

class ValuePropagatingElement {
  constructor(element) {
    this.sourceElement = element;
    this.destinationElement = document.getElementById(element.dataset.autopropagateValueTo);

    if (this.sourceElement.type != 'date') {
      console.error('ValuePropagatingElement supports only input[type=date] elements');
      return;
    }

    if (this.sourceElement.type != this.destinationElement.type) {
      console.error('Elements passed to ValuePropagatingElement do not have matching types');
      return;
    }

    this.bind();
  }

  copyValueIfNeeded = () => {
    const destinationValue = this.destinationElement.valueAsDate;
    const sourceValue = this.sourceElement.valueAsDate;

    if (destinationValue > sourceValue) {
      return;
    }

    this.copyValue();
  };

  copyValue = () => {
    this.destinationElement.value = this.sourceElement.value;
    this.destinationElement.dispatchEvent(new Event('change'));
  };

  bind() {
    this.sourceElement.addEventListener('change', this.copyValueIfNeeded);
  }
}

onLoad(() => {
  tie.bindClass('[data-autopropagate-value-to]', ValuePropagatingElement);
});
