import { useState, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import { forwardRef } from 'preact/compat';
import { html } from 'htm/preact';
import ReactPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { renderComponent } from '@app/dom';
import { i18n } from '@app/i18n';

const Input = forwardRef(({ placeholder, isSecondary, ...props }, ref) => {
  return isSecondary
    ? html`<input
        ref=${ref}
        placeholder=${placeholder}
        ...${props}
        className="c-input c-input--secondary u-size--match-parent-width"
      />`
    : html`<label className="c-label u-size--match-parent-width">
        <input ref=${ref} placeholder=${placeholder} ...${props} className="c-input u-size--match-parent-width" />
        <span className="c-label__text">${placeholder}</span>
      </label>`;
});

const PhoneInput = ({ parentElement }) => {
  const inputName = parentElement.dataset.phoneInputName;
  const isoInputName = parentElement.dataset.phoneInputIsoName;
  const placeholder = parentElement.dataset.phoneInputPlaceholder;
  const isRequired = parentElement.dataset.phoneInputRequired === 'true';
  const defaultCountry = (parentElement.dataset.phoneInputDefaultCountry || '').toUpperCase();
  const initialValue = parentElement.dataset.phoneInputValue;
  const isSecondary = parentElement.classList.contains('c-phone-input--secondary');
  const inputRef = useRef(null);
  const [value, setValue] = useState(initialValue);

  const onChange = (newValue) => {
    const isValid = isValidPhoneNumber(newValue || '');

    if (inputRef.current) {
      inputRef.current.setCustomValidity(isValid ? '' : i18n.t('common.error_messages.phone_format_invalid'));
    }

    setValue(newValue);
  };

  return html`<${Fragment}>
    <${ReactPhoneInput}
      ref=${inputRef}
      inputComponent=${Input}
      onChange=${onChange}
      name=${inputName}
      required=${isRequired}
      placeholder=${placeholder}
      value=${value}
      defaultCountry=${defaultCountry}
      isSecondary=${isSecondary} />
    <input type="hidden" name=${isoInputName} value=${value} />
  </${Fragment}>`;
};

renderComponent('.c-phone-input', PhoneInput);
