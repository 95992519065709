import { Controller } from '@hotwired/stimulus';

export class ClipboardController extends Controller {
  static values = {
    text: String,
  };

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.textValue);
  }
}
