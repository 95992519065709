import environment from '@app/env';
import { tie } from '@app/dom';

(function (w, d) {
  if (w._dixa_) {
    return;
  }
  w._dixa_ = {
    invoke: function (m, a) {
      w._dixa_.pendingCalls = w._dixa_.pendingCalls || [];
      w._dixa_.pendingCalls.push([m, a]);
    },
    addListener: function (event, listener) {
      w._dixa_.pendingAddListenerCalls = w._dixa_.pendingAddListenerCalls || [];
      w._dixa_.pendingAddListenerCalls.push([event, listener]);
    },
  };
  var s = d.createElement('script');
  s.type = 'text/javascript';
  s.setAttribute('charset', 'utf-8');
  s.async = true;
  s.src = 'https://messenger.dixa.io/bootstrap.js';
  var before = d.getElementsByTagName('script')[0];
  before.parentNode.insertBefore(s, before);
})(window, document);

if ('dixaMessengerToken' in environment) {
  window._dixa_.invoke('init', {
    messengerToken: environment.dixaMessengerToken,
    hideToggler: true,
    showWidgetCloseButton: true,
  });

  if ('dixaJweToken' in environment) {
    window._dixa_.invoke('setUserIdentity', {
      type: 'verified',
      payload: { type: 'jwe', token: environment.dixaJweToken },
    });
  } else {
    window._dixa_.invoke('purgeUserIdentity');
  }
}

tie.bind('[data-show-dixa]', (element) => {
  element.addEventListener('click', () => {
    window._dixa_.invoke('startConversation');
  });
});
