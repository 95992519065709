import autoComplete from '@tarekraafat/autocomplete.js';
import { apiFetch, endpoints } from '@app/api';
import { tie } from '@app/dom';
import { i18n } from '@app/i18n';

export function complete(config = {}) {
  const completionKey = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '');

  const completion = new autoComplete({
    selector: config.inputSelector,
    data: config.data(config.inputSelector),
    searchEngine: 'strict',
    diacritics: true,
    highlight: true,
    debounce: 300,
    threshold: typeof config.threshold !== 'undefined' ? config.threshold : 3,
    trigger: config.trigger,
    events: config.events,
    resultsList: {
      id: `autocomplete-${completionKey}__list`,
      class: 'c-autocomplete__list',
      maxResults: config.maxResults || 10,
      noResults: true,
      element: (list, data) => {
        if (data.results.length || typeof config.noResultsPlaceholder === 'undefined') {
          return;
        }

        const resultItem = document.createElement('li');
        resultItem.setAttribute('class', 'c-autocomplete__item c-autocomplete__item--no-result');
        resultItem.setAttribute('tabindex', '1');
        resultItem.innerHTML = config.noResultsPlaceholder;

        list.appendChild(resultItem);
      },
    },
    resultItem: {
      id: `autocomplete-${completionKey}__item`,
      class: 'c-autocomplete__item',
      element: (element) => {
        const content = document.createElement('span');

        content.innerHTML = element.innerHTML;
        element.innerHTML = '';

        const icon = document.createElement('i');

        icon.setAttribute('class', 'fas fa-arrow-right');

        const iconContainer = document.createElement('span');

        iconContainer.setAttribute('class', 'c-autocomplete__item-icon');
        iconContainer.appendChild(icon);

        element.appendChild(content);
        element.appendChild(iconContainer);
      },
    },
  });

  completion.init();

  return completion;
}

tie.bind('.c-autocomplete input[data-complete="profession"]', (element) => {
  const shouldSubmit = ['', true].includes(element.dataset.completeAutosubmit);
  const textInputElement = element.nextElementSibling;

  async function fetchData() {
    const url = new URL(endpoints.professions, location.origin);

    url.searchParams.append('filter_by_name', textInputElement.value || '');

    const response = await apiFetch(url.toString());

    return response;
  }

  if (element) {
    fetchData().then((data) => {
      const selection = data.find((profession) => profession.id == element.value);

      textInputElement.value = selection?.name ?? '';
    });
  }

  const professionsComplete = complete({
    inputSelector: () => textInputElement,
    data: () => ({
      src: fetchData,
      keys: ['name'],
      cache: false,
    }),
    threshold: 0,
    maxResults: Infinity,
    events: {
      input: {
        focus: () => {
          if (!textInputElement.value) {
            professionsComplete.start();
          }
        },
        selection: (event) => {
          const selection = event.detail.selection;
          const inputField = event.target;
          const professionName = selection.value[selection.key];
          const professionId = selection.value.id;

          inputField.value = professionName;
          element.value = professionId;
          inputField.blur();

          if (shouldSubmit) {
            inputField.form.submit();
          }
        },
      },
    },
    noResultsPlaceholder: i18n.t('newdawn.landing.find_profession.no_results'),
  });
});
