import { html } from 'htm/preact';
import { useState, useEffect, useRef, useCallback, useMemo } from 'preact/hooks';

import { createDropdown } from '@app/scripts/dropdown';
import { Link } from '@app/widgets/components/Link';
import { Modal, useModal } from '@app/widgets/components/Modal';
import { Spinner } from '@app/widgets/components/Spinner';
import { i18n } from '@app/i18n';
import { apiFetch, endpoints } from '@app/api';
import { renderComponent } from '@app/dom';

const TemplateListItem = ({ template, onDeleteTemplateClick, useTemplateLink }) => {
  const name = template.name || template.profession.name;
  const createdAtDate = new Date(template.created_at);
  const createdAt = createdAtDate.toLocaleDateString?.() ?? createdAt.toString();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownRef.current) {
      createDropdown(dropdownRef.current);
    }
  }, []);

  return html`<div class="o-layout o-layout--space-between o-layout--align-center u-margin-vertical--xs">
    <div class="o-layout__item o-layout__item--grow">
      <${Link} class="u-display--block u-padding-vertical--s" href=${useTemplateLink}>
        <div class="c-typography c-typography--small c-typography--strong u-color--ui-02">${name}</div>
      </${Link}>
    </div>
    <div class="o-layout__item">
      <${Link} class="u-padding-vertical--s" href=${useTemplateLink}>
        <span class="c-status c-status--is-neutral">
          ${i18n.t('offer.edit.post_job_modal.created_at', { date: createdAt })}
        </span>
      </${Link}>
      <div class="c-dropdown c-dropdown--right c-dropdown--bottom u-margin-left--m" tabindex="0" ref=${dropdownRef}>
            <div class="c-button c-button--more u-cursor--pointer">
              <i class="fas fa-ellipsis-h"></i>
            </div>
            <div class="c-dropdown__content u-padding-vertical--s">
              <${Link} href=${useTemplateLink} class="c-dropdown-link">
                ${i18n.t('offer.edit.post_job_modal.use_template')}
              </${Link}>
              <div onClick=${onDeleteTemplateClick} class="c-dropdown-link c-dropdown-link--caution u-cursor--pointer">
                ${i18n.t('offer.edit.post_job_modal.delete_template')}
              </div>
            </div>
          </div>
    </div>
  </div>`;
};

const OfferCreateModal = ({ parentElement }) => {
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const title = i18n.t('offer.edit.post_job_modal.title');
  const onCloseButtonClick = () => setIsOpen(false);
  const [templates, setTemplates] = useState(null);

  const deleteOffer = useCallback(async (id) => {
    try {
      const response = await apiFetch(endpoints.offerTemplates(id), { method: 'DELETE' });
      const filteredTemplates = templates.filter((template) => template.id !== response.id);

      setTemplates(filteredTemplates);
    } catch (error) {
      alert(error);
    }
  });

  useEffect(() => {
    const myTemplatesEndpoint = `${endpoints.myOfferTemplates}?count=50`;

    if (!isOpen) {
      setTemplates(null);
      return;
    }

    apiFetch(myTemplatesEndpoint)
      .then((payload) => {
        setTemplates(payload.objects);
      })
      .catch(() => {
        setTemplates(null);
      });
  }, [isOpen]);

  const templateItems = useMemo(() => {
    if (!templates) {
      return html`<${Spinner} size=${24} />`;
    }

    if (!templates.length) {
      return html`<p
        class="c-typography c-typography--small c-typography--strong u-margin-vertical--l u-text--align-center u-color--ui-04"
      >
        ${i18n.t('offer.edit.post_job_modal.list_placeholder')}
      </p>`;
    }

    return templates.map(
      (template) => html`<${TemplateListItem}
        key=${template.id}
        template=${template}
        onDeleteTemplateClick=${() => deleteOffer(template.id)}
        useTemplateLink=${`${triggerData.actionTemplateOfferLink}${template.id}`}
      />`,
    );
  }, [templates]);

  if (!isOpen) {
    return null;
  }

  return html`<${Modal} title=${title} isOpen=${isOpen} onRequestClose=${onCloseButtonClick}>
    <div className="u-padding--l">
      <p class="c-typography c-typography--paragraph u-color--ui-02">
        ${i18n.t('offer.edit.post_job_modal.description')}
      </p>
      <div className="u-margin-vertical--l u-color--ui-02">
        ${templateItems}
      </div>
      <div className="o-layout o-layout--justify-end u-margin-top--xxl">
        <div className="o-layout__item u-padding-horizontal--xs">
          <span className="c-button c-button--secondary" onClick="${onCloseButtonClick}">
          ${i18n.t('common.cancel')}
          </span>
        </div>
        <div className="o-layout__item u-padding-horizontal--xs">
          <${Link} href="${triggerData.actionEmptyOfferLink}" className="c-button c-button--primary">
            ${i18n.t('offer.edit.post_job_modal.create_new')}
          </${Link}>
        </div>
      </div>
    </div>
  </${Modal}>`;
};

renderComponent('#offer-create-modal', OfferCreateModal);
