import { Controller } from '@hotwired/stimulus';
import { i18n } from '@app/i18n';

// Connects to data-controller="break-input"
export class BreakInputController extends Controller {
  static targets = ['startInput', 'endInput', 'container', 'input', 'submit'];

  static values = {
    placeholderClass: { type: String, default: 'text-neutral-400' },
  };

  #startValue = '';
  #endValue = '';

  connect() {
    this.#saveValues();
    this.dispatch('save', { detail: this.#saveEventDetail(false) });
  }

  focus() {
    this.containerTarget.classList.add(this.#focusClass);
  }

  blur() {
    this.containerTarget.classList.remove(this.#focusClass);
  }

  save(e) {
    e.preventDefault();

    this.#saveValues();
    this.dispatch('save', { detail: this.#saveEventDetail(true) });
  }

  clear(e) {
    e.preventDefault();

    this.#clearInputValues();
    this.#saveValues();

    this.dispatch('save', { detail: this.#saveEventDetail(true) });
  }

  revert() {
    this.#clearInputValues();
    this.startInputTarget.value = this.#startValue;
    this.endInputTarget.value = this.#endValue;
  }

  get #focusClass() {
    return 'x-input--focused';
  }

  get #breakDurationInMinutes() {
    if (this.#startValue === '' || this.#endValue === '') {
      return null;
    }

    const startDate = this.#timeToDate(this.#startValue);
    const endDate = this.#timeToDate(this.#endValue);

    const result = (endDate - startDate) / 1000 / 60;

    return result < 0 ? result + (24 * 60) : result;
  }

  #clearInputValues() {
    // Need to set a value in order to clear any partial inputs i.e. if the
    // input state is "11:--" the value is empty and setting it to empty string
    // has no effect. Setting a non-empty value before makes the input clear
    // any text inside.
    this.startInputTarget.value = '01:23';
    this.endInputTarget.value = '01:23';
    this.startInputTarget.value = '';
    this.endInputTarget.value = '';
  }

  #timeToDate(timeString) {
    const splitted = timeString.split(':');
    return new Date(0, 0, 0, splitted[0], splitted[1], 0);
  }

  #saveValues() {
    this.#startValue = this.startInputTarget.value;
    this.#endValue = this.endInputTarget.value;

    this.#updateUI();
  }

  #updateUI() {
    const durationInMinutes = this.#breakDurationInMinutes;

    if (durationInMinutes !== null) {
      this.inputTarget.innerText = i18n.t('break_input.break_summary', { duration: `${durationInMinutes}m` });
      this.inputTarget.classList.remove(this.placeholderClassValue);
      this.submitTarget.innerText = i18n.t('break_input.pop_up.action.update');
    } else {
      this.inputTarget.innerText = i18n.t('break_input.no_break_label');
      this.inputTarget.classList.add(this.placeholderClassValue);
      this.submitTarget.innerText = i18n.t('break_input.pop_up.action.add');
    }
  }

  #saveEventDetail(userInitiated) {
    let result = { userInitiated: userInitiated, break: null };

    if (this.#startValue === '' || this.#endValue === '') {
      return result;
    }

    result.break = {
      start: this.#startValue,
      end: this.#endValue,
    };
    return result;
  }
}
