import { html } from 'htm/preact';
import { useEffect, useState } from 'preact/hooks';

import { renderComponent, classNames } from '@app/dom';
import { apiFetch, endpoints } from '@app/api';
import { Spinner } from '@app/widgets/components/Spinner';

const ProfessionCategory = ({ category, selectedProfessionIds, onClick }) => {
  const professions = category.professions.map((profession) => {
    const isProfessionSelected = selectedProfessionIds.includes(profession.id);

    return html`<div
      key=${profession.id}
      className=${classNames('c-selectable-item', isProfessionSelected && 'c-selectable-item--selected')}
      onClick=${() => onClick(profession)}
    >
      ${profession.icon && html`<span className="u-padding-right--s"><i className=${profession.icon}></i></span>`}
      ${profession.name}
    </div>`;
  });

  return html`<div className="c-tile u-margin-vertical--xl u-padding--m">
    <div className="o-layout o-layout--align-center u-margin-horizontal--xs u-margin-top--m u-margin-bottom--xl">
      <div className="o-layout__item">
        <div className="c-typography c-typography--header-3 u-color--ui-02 u-margin-horizontal--s u-text--zero-height">
          <i className=${category.icon}></i>
        </div>
      </div>
      <div className="o-layout__item">
        <div className="c-typography c-typography--header-3 u-color--ui-02 u-margin-horizontal--xs">
          ${category.name}
        </div>
      </div>
    </div>
    <div className="o-layout o-layout--justify-center">${professions}</div>
  </div>`;
};

const ProfessionsChoice = ({ parentElement }) => {
  const [selectElement, setSelectElement] = useState();
  const [professionCategories, setProfessionCategories] = useState([]);
  const [selectedProfessionIds, setSelectedProfessionIds] = useState([]);
  const selectElementId = parentElement.dataset.bindTo;

  useEffect(() => {
    apiFetch(endpoints.professionCategories).then((response) => {
      setProfessionCategories(response.objects);
    });
  }, []);

  useEffect(() => {
    const element = document.getElementById(selectElementId);

    if (!(element instanceof HTMLSelectElement)) {
      throw new Error('This component must be binded to <select>');
    }

    const values = Array.from(element.selectedOptions).map((option) => parseInt(option.value, 10));

    setSelectElement(element);
    setSelectedProfessionIds(values);
  }, [selectElementId]);

  useEffect(() => {
    const options = Array.from(selectElement?.options ?? []);

    options.forEach((option) => {
      const value = parseInt(option.value, 10);

      option.selected = selectedProfessionIds.includes(value);
    });
  }, [selectedProfessionIds]);

  const onProfessionClick = (profession) => {
    if (selectedProfessionIds.includes(profession.id)) {
      setSelectedProfessionIds(selectedProfessionIds.filter((id) => id !== profession.id));
    } else {
      setSelectedProfessionIds([...selectedProfessionIds, profession.id]);
    }

    const event = new Event('change', { bubbles: true });

    selectElement.dispatchEvent(event);
  };

  return professionCategories.length
    ? html`<div className="animate__animated animate__fadeIn">
        ${professionCategories.map(
          (category) =>
            html`<${ProfessionCategory}
              category=${category}
              selectedProfessionIds=${selectedProfessionIds}
              onClick=${onProfessionClick}
              key=${category.id}
            />`,
        )}
      </div>`
    : html`<${Spinner} size=${24} />`;
};

renderComponent('.c-professions-choice', ProfessionsChoice);
