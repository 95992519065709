import { html } from 'htm/preact';
import { useCallback, useEffect, useState } from 'preact/hooks';

import { Modal, useModal } from '@app/widgets/components/Modal';
import { Spinner } from '@app/widgets/components/Spinner';
import { renderComponent } from '@app/dom';
import { i18n } from '@app/i18n';
import { apiFetch, endpoints } from '@app/api';

const StaffWorkerCommentModal = ({ parentElement }) => {
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const title = i18n.t('employer.profile.staff_workers.comment_modal.title');
  const onCloseButtonClick = () => setIsOpen(false);
  const [comment, setComment] = useState(null);

  const updateStaffWorker = useCallback((payload) => {
    apiFetch(endpoints.staff(triggerData.workerId), {
      method: 'PUT',
      body: JSON.stringify(payload),
    })
      .then(() => {
        // let's reload the page with staff workers
        window.location.reload();
      })
      .catch((error) => {
        setComment('');
        alert(error);
      });
  });

  const onSubmit = useCallback(() => {
    updateStaffWorker({ comment });
  });

  const onDeleteButtonClick = useCallback(() => {
    updateStaffWorker({ comment: '' });
  });

  const onChange = useCallback((event) => {
    setComment(event.target.value);
  });

  useEffect(() => {
    if (!isOpen) {
      setComment(null);
      return;
    }

    apiFetch(endpoints.staff(triggerData.workerId))
      .then((payload) => {
        setComment(payload.comment);
      })
      .catch((error) => {
        setComment('');
        alert(error);
      });
  }, [isOpen, triggerData]);

  if (!isOpen) {
    return null;
  }

  return html`<${Modal} title=${title} isOpen=${isOpen} onRequestClose=${onCloseButtonClick}>
    <div className="u-padding--l">
      ${
        comment === null
          ? html`<${Spinner} size=${24} />`
          : html`<label class="c-label">
              <textarea rows="6" data-type="textarea" class="c-input u-size--match-parent-width" onChange=${onChange}>
                ${comment}
              </textarea>
              <span class="c-label__text">${i18n.t('employer.profile.staff_workers.comment_modal.comment')}</span>
            </label>`
      }
      <div className="o-layout o-layout--space-between u-margin-top--xl">
        <div className="o-layout__item u-padding-horizontal--xs">
          <span className="c-button c-button--caution" onClick=${onDeleteButtonClick}>
            ${i18n.t('common.delete')}
          </span>
        </div>
        <div className="o-layout__item u-padding-horizontal--xs">
          <span className="c-button c-button--secondary u-margin-right--s" onClick=${onCloseButtonClick}>
            ${i18n.t('common.cancel')}
          </span>
          <button onClick=${onSubmit} class="c-button c-button--primary">${i18n.t('common.save')}</button>
        </div>
      </div>
    </div>
  </${Modal}>`;
};

renderComponent('#staff-worker-comment-modal', StaffWorkerCommentModal);
