import { tie } from '@app/dom';

tie.bind('[data-sidebar-switch]', (element) => {
  element.addEventListener('click', () => {
    const event = new CustomEvent('temply.toggleSidebar', { bubbles: true });

    element.dispatchEvent(event);
  });
});

tie.bind('.c-sidebar', (element) => {
  window.addEventListener('temply.toggleSidebar', () => {
    element.classList.toggle('c-sidebar--opened');
  });
});
