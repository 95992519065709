import { endpoints, apiFetch } from '@app/api';
import { tie } from '@app/dom';
import { i18n } from '@app/i18n';

class CostsCalculator {
  constructor() {
    this.response = null;
  }

  getSocialCost() {
    return this.response.social_costs;
  }

  getTemplyCost() {
    return this.response.temply_fee;
  }

  getTemplyFee() {
    return this.response.temply_fee_percentage;
  }

  getSocialTax() {
    return this.response.social_costs_percentage;
  }

  getTotalCost() {
    return this.response.total_cost;
  }

  async calculate(hourlyRate, isSimulation) {
    const endpoint = isSimulation ? endpoints.simulateCosts : endpoints.calculateCosts;
    const url = new URL(endpoint, location.origin);

    url.searchParams.append('hourly_rate', parseFloat(hourlyRate) || 0);

    const data = await apiFetch(url);

    this.response = data;
  }
}

tie.bind('input[data-cost-calculator]', (input) => {
  if (!input) {
    return;
  }

  const isSimulation = ['', 'true'].includes(input.getAttribute('data-cost-simulation'));
  const resultElementId = input.getAttribute('data-cost-calculator');
  const resultElement = document.getElementById(resultElementId);
  const calculator = new CostsCalculator();

  let timeoutId;

  async function onChange() {
    const formatter = new Intl.NumberFormat(i18n.locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    await calculator.calculate(input.value, isSimulation);

    resultElement.innerHTML = i18n.t('offer.edit.cost_calculation', {
      social_cost: formatter.format(calculator.getSocialCost()),
      social_tax: formatter.format(calculator.getSocialTax()),
      temply_cost: formatter.format(calculator.getTemplyCost()),
      temply_fee: formatter.format(calculator.getTemplyFee()),
      total_cost: formatter.format(calculator.getTotalCost()),
    });

    timeoutId = undefined;
  }

  input.addEventListener('keyup', () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(onChange, 500);
  });
});
