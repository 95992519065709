import { tie } from '@app/dom';
import { endpoints, apiFetch } from '@app/api';

tie.bind('.c-notifications-dropdown .c-dropdown', (element) => {
  element.addEventListener('click', () => {
    element
      .querySelector('.c-notifications-dropdown__icon--new')
      ?.classList.remove('c-notifications-dropdown__icon--new');

    apiFetch(endpoints.markNewNotificationsOpened, { method: 'POST' });
  });
});
