import { tie } from '@app/dom';

tie.bind('[data-href]', (element) => {
  function onClick(event) {
    const href = element.dataset.href;
    let child = event.target;

    if (href === '') {
      return;
    }

    do {
      const isSelf = child === element;

      if (isSelf) {
        location.href = href;
        break;
      }

      const isAnchor = child instanceof HTMLAnchorElement;
      const isDynamicAnchor = child.hasAttribute('data-href');
      const isFocusable = child.hasAttribute('tabindex');

      if (isAnchor || isDynamicAnchor || isFocusable) {
        break;
      }

      child = child.parentElement;
    } while (element.contains(child));
  }

  element.addEventListener('click', onClick);

  return () => {
    element.removeEventListener('click', onClick);
  };
});
