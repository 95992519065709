import { html } from 'htm/preact';
import Rails from '@rails/ujs';

export const Form = ({ method, children, ...props }) => {
  const addMethodField = method && !/^get|post$/i.test(method);

  return html`<form accept-charset="UTF-8" method=${method || 'post'} ...${props}>
    <input name="utf8" type="hidden" value="✓" />
    <input type="hidden" name="authenticity_token" value=${Rails.csrfToken()} />
    ${addMethodField && html`<input name="_method" type="hidden" value=${method} />`} ${children}
  </form>`;
};
