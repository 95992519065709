import { i18n } from '@app/i18n';

export const endpoints = {
  calculateCosts: '/api/v1/costs/calculate',
  simulateCosts: '/api/v1/costs/simulate',
  markNewNotificationsOpened: '/api/v1/notifications/seen_new',
  professions: '/api/v1/professions_list',
  professionCategories: '/api/v1/profession_categories',
  usersMe: '/api/v1/users/me',
  myOfferTemplates: '/api/v1/users/me/offer_templates',
  offerTemplates: (id) => `/api/v1/offer_templates/${id ?? ''}`,
  staff: (id) => `/api/v1/workers/staff/${id ?? ''}`,
};

export const apiFetch = (url, { headers = {}, ...options } = {}) => {
  const [, token] = document.cookie.match(/ ?api_access_token=([0-9a-z]+)/i) || [];
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};

  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.locale,
      ...authorizationHeader,
      ...headers,
    },
    ...options,
  })
    .then((response) => response.json())
    .then(async (json) => {
      if (json.status_code !== 'OK') {
        throw new Error(`[API] ${json.status_code}: ${json.message || '<empty message>'}`);
      }

      return json.data;
    });
};
