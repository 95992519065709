import { tie, onLoad } from '@app/dom';
import { i18n } from '@app/i18n';

const buildIdFromName = (name) => name.replace(/[[\]]/g, '_').replace(/[_]+/g, '_');

class ScheduleTimeField {
  constructor(element) {
    this.timeField = element.querySelector('input[type="time"]');

    const data = this.timeField.dataset;

    this.hourField = element.querySelector(buildIdFromName(`#${data.objectName}_${data.hourField}`));
    this.minuteField = element.querySelector(buildIdFromName(`#${data.objectName}_${data.minuteField}`));

    this.fromHourField = document.querySelector(buildIdFromName(`#${data.objectName}_hour_from`));
    this.fromMinuteField = document.querySelector(buildIdFromName(`#${data.objectName}_minute_from`));
    this.toHourField = document.querySelector(buildIdFromName(`#${data.objectName}_hour_to`));
    this.toMinuteField = document.querySelector(buildIdFromName(`#${data.objectName}_minute_to`));
    this.timeInWordsField = document.querySelector(`[name="${data.objectName}_time_in_words"]`);

    this.bind();
  }

  copyValue = (event) => {
    const [hours, minutes] = (event.target.value || ':').split(':');

    this.hourField.value = hours;
    this.minuteField.value = minutes;
  };

  calculateTimeInWords = () => {
    const hourFrom = parseInt(this.fromHourField.value) || 0;
    const hourTo = parseInt(this.toHourField.value) || 0;
    const minuteFrom = parseInt(this.fromMinuteField.value) || 0;
    const minuteTo = parseInt(this.toMinuteField.value) || 0;

    let minuteDiff = hourTo * 60 + minuteTo - (hourFrom * 60 + minuteFrom);

    if (minuteDiff < 0) {
      minuteDiff += 60 * 24;
    }

    const hoursTotal = Math.floor(minuteDiff / 60);
    const minutesTotal = minuteDiff % 60;

    this.timeInWordsField.innerText = i18n.t('date.time.words', { hours: hoursTotal, minutes: minutesTotal });
  };

  onChange = (event) => {
    this.copyValue(event);
    this.calculateTimeInWords(event);
  };

  bind() {
    this.timeField.addEventListener('change', this.onChange);
  }
}

onLoad(() => {
  tie.bind('.schedule-time-field', (element) => new ScheduleTimeField(element));
});
