import { tie } from '@app/dom';

export const createDropdown = (element) => {
  const openClass = 'c-dropdown--open';
  const contentClass = 'c-dropdown__content';
  const enterKeyCode = 13;

  if (!element.hasAttribute('tabindex')) {
    element.setAttribute('tabindex', 0);
  }

  element.addEventListener('click', (event) => {
    const contentElement = element.querySelector(`.${contentClass}`);
    const isContentClick = contentElement && contentElement.contains(event.target);

    if (!isContentClick) {
      event.currentTarget.classList.toggle(openClass);
    }
  });

  element.addEventListener('blur', (event) => {
    event.currentTarget.classList.remove(openClass);
  });

  element.addEventListener('keyup', (event) => {
    if (event.keyCode === enterKeyCode) {
      event.currentTarget.click();
    }
  });
};

tie.bind('.c-dropdown', createDropdown);
