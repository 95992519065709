import { tie } from '@app/dom';

tie.bind('[data-toggle]', (element) => {
  const stateEven = 'even';
  const stateOdd = 'odd';

  element.dataset.toggleState = stateEven;

  element.addEventListener('click', () => {
    const event = new CustomEvent('temply.toggle', {
      bubbles: true,
      detail: { id: element.dataset.toggle },
    });

    element.dispatchEvent(event);

    element.dataset.toggleState = element.dataset.toggleState === stateEven ? stateOdd : stateEven;
  });
});

tie.bind('[data-toggle-class]', (element) => {
  window.addEventListener('temply.toggle', (event) => {
    if (element.getAttribute('id') !== event.detail.id) {
      return;
    }

    const classNames = element.dataset.toggleClass.split(' ');

    classNames.forEach((className) => element.classList.toggle(className));
  });
});
