import { tie, onLoad } from '@app/dom';

class OfferFormComponent {
  constructor(element) {
    this.form = element;
    this.dateFromElement = this.form.querySelector('#offer_date_from');
    this.dateToElement = this.form.querySelector('#offer_date_to');
    this.dayCheckboxes = Array(7)
      .fill()
      .map((_, index) =>
        this.form.querySelector(`input[type=checkbox][data-general-schedule-checkbox-day="${index + 1}"]`),
      );

    this.bind();
  }

  calculateSchedule = () => {
    this.dayCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
      checkbox.dispatchEvent(new Event('change'));
    });

    const dateFrom = this.dateFromElement.valueAsDate;
    const dateTo = this.dateToElement.valueAsDate;
    const weekSinceStartDate = new Date(dateFrom).setDate(dateFrom.getDate() + 7);
    const endDate = dateTo < weekSinceStartDate ? dateTo : weekSinceStartDate;
    let date = new Date(this.dateFromElement.valueAsDate);
    while (date <= endDate) {
      let day = date.getDay();
      if (day == 0) {
        // JavaScript sunday = 0, our sunday = 7
        day = 7;
      }

      let checkbox = this.dayCheckboxes[day - 1];
      checkbox.checked = true;
      checkbox.dispatchEvent(new Event('change'));

      date.setDate(date.getDate() + 1);
    }
  };

  bind() {
    this.dateFromElement.addEventListener('change', this.calculateSchedule);
    this.dateToElement.addEventListener('change', this.calculateSchedule);
  }
}

onLoad(() => {
  tie.bindClass('form#new_offer', OfferFormComponent);
});
