import { i18n } from '@app/i18n';

const maps = {
  initGooglePlacesPostalCodeCompletion() {
    const postalCodeInput = document.querySelector('#locationInput');
    const placeIdInput = document.querySelector('#google_place_id');
    const latInput = document.querySelector('#offer_lat');
    const lngInput = document.querySelector('#offer_lng');

    if (!postalCodeInput) {
      return;
    }

    var autoSubmit = !postalCodeInput.hasAttribute('data-no-autosubmit');
    let formSubmittable = false;

    var userCoordsInputs = {
      lat: postalCodeInput.form.querySelector('.user_lat'),
      lng: postalCodeInput.form.querySelector('.user_lng'),
    };
    var userCoords = {
      lat: userCoordsInputs.lat && userCoordsInputs.lat.value,
      lng: userCoordsInputs.lng && userCoordsInputs.lng.value,
    };
    const autocompleteOptions = {
      types: ['(regions)'],
      componentRestrictions: {
        country: 'dk',
      },
    };
    const placesAutocomplete = new window.google.maps.places.Autocomplete(postalCodeInput, autocompleteOptions);
    const geolocation = new window.google.maps.LatLng(userCoords.lat, userCoords.lng);
    const circle = new window.google.maps.Circle({
      center: geolocation,
      radius: 180000,
    });
    placesAutocomplete.setBounds(circle.getBounds());
    placesAutocomplete.setTypes(['address']);
    window.google.maps.event.addListener(placesAutocomplete, 'place_changed', function autocompletePlaceSelected() {
      const place = placesAutocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      const location = place.geometry.location;

      placeIdInput.value = place.place_id;

      latInput.value = location.lat();
      lngInput.value = location.lng();

      formSubmittable = true;

      if (autoSubmit) {
        postalCodeInput.form.submit();
      }
    });

    postalCodeInput.form.addEventListener('submit', function locationFormSubmit(event) {
      if (!formSubmittable) {
        event.preventDefault();
      }
    });
  },

  initGooglePlacesFullAddressCompletion() {
    var locationInput = document.getElementById('locationFullInput');
    var latInput = document.querySelector('#worker_lat');
    var lngInput = document.querySelector('#worker_lng');

    if (locationInput != null) {
      var locationInputForm = locationInput.form;
      var autoSubmit = !locationInput.hasAttribute('data-no-autosubmit');
      var shouldFormPrevent = autoSubmit;
      locationInputForm.addEventListener('submit', function (event) {
        if (shouldFormPrevent) {
          event.preventDefault();
        }
      });
      var userCoordsInputs = {
        lat: locationInputForm.querySelector('.user_lat'),
        lng: locationInputForm.querySelector('.user_lng'),
      };
      var userCoords = {
        lat: userCoordsInputs.lat && userCoordsInputs.lat.value,
        lng: userCoordsInputs.lng && userCoordsInputs.lng.value,
      };
      var placesAutocomplete = new window.google.maps.places.Autocomplete(locationInput);
      var geolocation = new window.google.maps.LatLng(userCoords.lat, userCoords.lng);
      var circle = new window.google.maps.Circle({
        center: geolocation,
        radius: 250000,
      });
      placesAutocomplete.setBounds(circle.getBounds());
      placesAutocomplete.setTypes(['address']);
      window.google.maps.event.addListener(placesAutocomplete, 'place_changed', function autocompletePlaceSelected() {
        var place = placesAutocomplete.getPlace();

        locationInput.setCustomValidity('');

        var addressContainsStreetNumber = place.address_components.some((component) => component.types.includes('street_number'));

        if (!addressContainsStreetNumber) {
          var selectedStreetName = locationInput.value.split(',')[0];
          var fetchedStreetName = place.formatted_address.split(',')[0];
          var streetNameWithStreetNumberRegExp = RegExp(fetchedStreetName + ' [0-9]+$');
          if (!selectedStreetName.match(streetNameWithStreetNumberRegExp)) {
            locationInput.setCustomValidity(i18n.t('offer.edit.street_number_error'));
            return;
          }
        }

        if (!place.geometry) {
          return;
        }

        var location = place.geometry.location;

        latInput.value = location.lat();
        lngInput.value = location.lng();

        var reloadMapCallbackName = locationInput.getAttribute('data-reload-map');

        if (typeof window[reloadMapCallbackName] === 'function') {
          window[reloadMapCallbackName](location.lat(), location.lng());
        }

        shouldFormPrevent = false;

        if (autoSubmit) {
          locationInputForm.submit();
        }
      });
    }
  },

  InteractiveMap: function InteractiveMap(options) {
    this.options = options;
    this.map = null;
    this.marker = null;
    this.mapId = null;
    this.markerRadius = null;
    this.placesAutocomplete = null;
    this.maxBoundsRadius = 3500;

    this.defaultOptions = {
      radius: 1,
      initialPosition: { lat: 54, lng: 14 },
      radiusEnabled: false,
      markerEnabled: true,
      draggable: true,
      disableUi: false,
      markerIcon: { url: '/images/marker.svg' },
      zoom: 13,
    };

    this.strictBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(85, -180),
      new window.google.maps.LatLng(-85, 180),
    );

    function createMarkerIcon(markerOptions) {
      var result = {
        origin: new window.google.maps.Point(0, 0),
        url: markerOptions.url,
      };

      if (markerOptions.size) {
        result.size = new window.google.maps.Size(markerOptions.size.width, markerOptions.size.height);
      }

      if (markerOptions.anchor) {
        result.anchor = new window.google.maps.Point(markerOptions.anchor.x, markerOptions.anchor.y);
      }

      return result;
    }

    this.initialize = function initialize() {
      this.options = Object.assign(this.defaultOptions, this.options);
      this.map = this.options.map;

      this.mapId = options.id;

      this.bindInputs();

      if (this.options.markerEnabled) {
        this.marker = new window.google.maps.Marker({
          position: this.options.initialPosition,
          map: this.map,
          draggable: this.options.draggable,
          icon: createMarkerIcon(this.options.markerIcon),
        });

        this.marker.addListener('dragend', this.handleMarkerDragEnd);
      }

      if (this.options.radiusEnabled) {
        // Add circle overlay and bind to marker
        this.markerRadius = new window.google.maps.Circle({
          map: this.map,
          radius: this.options.radius * 1000,
          fillColor: '#65C7C3',
          fillOpacity: 0.2,
          strokeColor: '#65C7C3',
          clickable: false,
          strokeOpacity: 0.9,
          strokeWeight: 2,
        });

        this.markerRadius.bindTo('center', this.marker, 'position');
        this.setMapBounds(this.markerRadius.radius / 1000);

        document.querySelector('#interactive_map_' + this.mapId + '_working_radius').value = this.sliderValueForRadius(
          this.options.radius,
        );
        document.querySelector('#interactive_map_' + this.mapId + '_radius').value = this.options.radius;
      }

      if (this.options.draggable) {
        window.google.maps.event.addListener(
          this.map,
          'click',
          function googleMapClickListener(event) {
            this.marker.setPosition(event.latLng);
            this.updateFormCoordinates(event.latLng.lat(), event.latLng.lng());
          }.bind(this),
        );
      }

      if (this.options.initialPosition.lat === 0 && this.options.initialPosition.lat === 0) {
        if ('geolocation' in window.navigator) {
          navigator.geolocation.watchPosition(
            function navigatorWatchPosition(position) {
              this.navigateTo(position.coords.latitude, position.coords.longitude);
              this.fillLocation(position.coords.latitude, position.coords.longitude);
            }.bind(this),
            function watchPositionError() {
              this.navigateToLocationByIpAddress();
            }.bind(this),
          );
        } else {
          this.navigateToLocationByIpAddress();
        }
      }

      this.map.setOptions({ draggable: this.options.draggable });

      if (this.options.disableUi) {
        this.map.setOptions({
          disableDoubleClickZoom: true,
          disableDefaultUI: true,
          scaleControl: false,
          navigationControl: false,
          scrollwheel: false,
        });
      }

      this.map.setZoom(this.options.zoom);

      this.updateWorkingRadiusValue();
    }.bind(this);

    this.handleMarkerDragEnd = function handleMarkerDragEnd(event) {
      this.updateFormCoordinates(event.latLng.lat(), event.latLng.lng());
    }.bind(this);

    this.bindInputs = function bindInputs() {
      var placeField = document.querySelector('#interactive_map_' + this.mapId + '_place_field');
      var searchField = document.querySelector('#interactive_map_' + this.mapId + '_search');

      if (searchField) {
        searchField.addEventListener('click', this.searchByInput);
      }

      var latField = document.querySelector('.user_lat');
      var lngField = document.querySelector('.user_lng');
      var userCoords = {
        lat: latField && latField.value,
        lng: lngField && lngField.value,
      };

      if (placeField) {
        placeField.addEventListener('keypress', this.searchByInput);
        this.placesAutocomplete = new window.google.maps.places.Autocomplete(placeField);
        var geolocation = new window.google.maps.LatLng(userCoords.lat, userCoords.lng);
        var circle = new window.google.maps.Circle({
          center: geolocation,
          radius: 250000,
        });
        this.placesAutocomplete.setBounds(circle.getBounds());
        this.placesAutocomplete.setTypes(['address']);
        window.google.maps.event.addListener(this.placesAutocomplete, 'place_changed', this.autocompletePlaceSelected);
      }

      if (this.options.radiusEnabled) {
        var workingRadiusField = document.querySelector('#interactive_map_' + this.mapId + '_working_radius');

        if (workingRadiusField) {
          workingRadiusField.addEventListener('change', this.changeWorkingRadius);
          workingRadiusField.addEventListener('input', this.updateWorkingRadiusValue);
        }
      }
    }.bind(this);

    this.changeWorkingRadius = function changeWorkingRadius(e) {
      var currentTarget = e.currentTarget;
      var workingRadius = currentTarget.value;

      if (workingRadius < 1 && workingRadius != '') {
        workingRadius = 1;

        currentTarget.value = workingRadius;
      }

      this.markerRadius.setRadius(this.radiusForSliderValue(workingRadius) * 1000);
      if (this.options.radiusEnabled) {
        this.setMapBounds(this.radiusForSliderValue(workingRadius));
      }
    }.bind(this);

    this.setMapBounds = function setMapBounds(workingRadius) {
      var bounds = workingRadius > this.maxBoundsRadius ? this.strictBounds : this.markerRadius.getBounds();
      this.map.fitBounds(bounds);
    }.bind(this);

    this.updateWorkingRadiusValue = function updateWorkingRadiusValue() {
      var radiusSlider = document.querySelector('#interactive_map_' + this.mapId + '_working_radius');

      if (!radiusSlider) {
        return;
      }

      radiusSlider.parentElement.parentElement.querySelector('.range-value').innerHTML
        = this.radiusForSliderValue(radiusSlider.value) + ' km';
      document.querySelector('#interactive_map_' + this.mapId + '_radius').value = this.radiusForSliderValue(
        radiusSlider.value,
      );
    }.bind(this);

    this.radiusForSliderValue = function radiusForSliderValue(value) {
      var radius = 0;

      switch (true) {
        case value <= 20:
          radius = value * 5;
          break;
        case value <= 35:
          radius = (value - 20) * 10 + 100;
          break;
        case value <= 50:
          radius = (value - 35) * 50 + 250;
          break;
        case value <= 75:
          radius = (value - 50) * 100 + 1000;
          break;
        case value <= 88:
          radius = (value - 75) * 500 + 3500;
          break;
        default:
          radius = (value - 88) * 1000 + 10000;
          break;
      }

      return radius;
    };

    this.sliderValueForRadius = function sliderValueForRadius(radius) {
      var value = 0;

      switch (true) {
        case radius <= 100:
          value = radius / 5;
          break;
        case radius <= 250:
          value = (radius - 100) / 10 + 20;
          break;
        case radius <= 1000:
          value = (radius - 250) / 50 + 35;
          break;
        case radius <= 3500:
          value = (radius - 1000) / 100 + 50;
          break;
        case radius <= 10000:
          value = (radius - 3500) / 500 + 75;
          break;
        default:
          value = (radius - 10000) / 1000 + 88;
      }

      return value;
    };

    this.autocompletePlaceSelected = function autocompletePlaceSelected() {
      var place = this.placesAutocomplete.getPlace();

      if (!place.geometry) {
        return;
      }

      var location = place.geometry.location;

      document.querySelector('#interactive_map_' + this.mapId + '_lat').value = location.lat();
      document.querySelector('#interactive_map_' + this.mapId + '_lng').value = location.lng();

      this.navigateTo(location.lat(), location.lng());
    }.bind(this);

    this.navigateTo = function navigateTo(lat, lng, zoom) {
      if (zoom === undefined) {
        zoom = 15; // eslint-disable-line no-param-reassign
      }

      this.marker.setPosition(new window.google.maps.LatLng(lat, lng));
      this.map.panTo(new window.google.maps.LatLng(lat, lng));

      if (this.options.radiusEnabled) {
        this.map.fitBounds(this.markerRadius.getBounds());
      }
      this.updateFormCoordinates(lat, lng);
    }.bind(this);

    this.updateFormCoordinates = function updateFormCoordinates(lat, lng) {
      document.querySelector('#interactive_map_' + this.mapId + '_latitude').value = lat;
      document.querySelector('#interactive_map_' + this.mapId + '_longitude').value = lng;
    };

    this.fillLocation = function fillLocation(latitude, longitude) {
      fetch(
        'https://maps.googleapis.com/maps/api/geocode/json?latlng='
        + latitude
        + ','
        + longitude
        + '&key='
        + this.options.apiKey,
      )
        .then((response) => response.json())
        .then(
          function (data) {
            var results = data.results;

            if (results.length > 0) {
              var firstResult = results[0];

              var cityName = this.getCityNameFromResult(firstResult);

              document.querySelector('#interactive_map_' + this.mapId + '_place_field').value = cityName;
              document.querySelector('#interactive_map_' + this.mapId + '_lat').value = latitude;
              document.querySelector('#interactive_map_' + this.mapId + '_lng').value = longitude;
            }
          }.bind(this),
        );
    }.bind(this);

    this.getCityNameFromResult = function getCityNameFromResult(result) {
      if ('address_components' in result) {
        for (var i = 0; i < result.address_components.length; ++i) {
          var addressComponent = result.address_components[i];

          if (addressComponent.types.indexOf('locality') !== -1 && addressComponent.types.indexOf('political') !== -1) {
            return addressComponent.long_name;
          }
        }
      }
    };

    this.navigateToLocationByIpAddress = function navigateToLocationByIpAddress() {
      fetch('/api/v1/geo/freegeoip')
        .then((response) => response.json())
        .then(
          function navigateToLocationByIpAddressDone(response) {
            var data = response.data;

            if (data.country_name === '') {
              return;
            }

            this.navigateToLocationByAddress(data.country_name, 5);
          }.bind(this),
        );
    }.bind(this);

    this.navigateToLocationByAddress = function navigateToLocationByAddress(address, zoom) {
      fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + this.options.apiKey)
        .then((response) => response.json())
        .then(
          function navigateToLocationByIpAddressRequestDone(data) {
            var results = data.results;

            if (results.length > 0) {
              var firstResult = results[0];

              // Navigate to the first result found
              if ('geometry' in firstResult) {
                this.navigateTo(firstResult.geometry.location.lat, firstResult.geometry.location.lng, zoom);
              }
            }
          }.bind(this),
        );
    }.bind(this);
  },
};

window.mapModule = maps;
window.mapCallbackArray = window.mapCallbackArray || [];
window.initGoogleMaps = function initGoogleMaps() {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initGoogleMaps);
    return;
  }

  maps.initGooglePlacesPostalCodeCompletion();
  maps.initGooglePlacesFullAddressCompletion();
  window.mapCallbackArray.forEach((callbackName) => window[callbackName]());
};
