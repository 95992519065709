import { html } from 'htm/preact';

import { renderComponent } from '@app/dom';
import { Modal, useModal } from '@app/widgets/components/Modal';
import { Link } from '@app/widgets/components/Link';
import { i18n } from '@app/i18n';

const ApproveHoursModal = ({ parentElement }) => {
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const title = i18n.t('engagement.log_hours.approve_hours_modal.title');
  const onCloseButtonClick = () => setIsOpen(false);

  return html`<${Modal} title=${title} isOpen=${isOpen} onRequestClose=${onCloseButtonClick}>
    <div className="u-padding--l">
      <p className="c-typography c-typography--paragraph u-margin-top--s u-margin-bottom--xl">
        ${i18n.t('engagement.log_hours.approve_hours_modal.confirm')}
      </p>
      <div className="o-layout o-layout--justify-end u-margin-top--xl">
        <div className="o-layout__item u-padding-horizontal--xs">
          <span className="c-button c-button--secondary" onClick="${onCloseButtonClick}">
            ${i18n.t('engagement.log_hours.approve_hours_modal.cancel')}
          </span>
        </div>
        <div className="o-layout__item u-padding-horizontal--xs">
          <${Link} href="${triggerData.actionLink}" method="post" className="c-button c-button--primary" data-analytics-event="employer_approves_hours">
            ${i18n.t('engagement.log_hours.approve_hours_modal.approve')}
          </${Link}>
        </div>
      </div>
    </div>
  </${Modal}>`;
};

renderComponent('#approve-hours-modal', ApproveHoursModal);
