import { html } from 'htm/preact';

import { renderComponent } from '@app/dom';
import { Modal, useModal } from '@app/widgets/components/Modal';
import { i18n } from '@app/i18n';
import { Form } from '@app/widgets/components/Form';

const WorkdayCheckinModal = ({ parentElement }) => {
  const [isOpen, setIsOpen, triggerData] = useModal(parentElement);
  const title = i18n.t('engagement.log_hours.workday_checkin_modal.title');
  const onCloseButtonClick = () => setIsOpen(false);

  return html`<${Modal} title=${title} isOpen=${isOpen} onRequestClose=${onCloseButtonClick}>
    <div className="u-padding--l">
        <${Form} action=${triggerData.actionLink} method="post">
          <label className="c-label">
            <input class="c-input u-size--match-parent-width" data-type="select" type="time"
                   name="checkin_time" id="checkin_time" required="required" />
            <span className="c-label__text">${i18n.t('engagement.log_hours.workday_checkin_modal.label_checkin_time')}</span>
          </label>
          <div className="o-layout o-layout--justify-end u-margin-top--xl">
            <div className="o-layout__item u-padding-horizontal--xs">
              <span className="c-button c-button--secondary" onClick=${onCloseButtonClick}>
                ${i18n.t('common.cancel')}
              </span>
            </div>
            <div className="o-layout__item u-padding-horizontal--xs">
              <input type="submit" className="c-button" value=${i18n.t(
      'engagement.log_hours.workday_checkin_modal.checkin',
    )} data-analytics-event="employer_checks_in_worker" />
            </div>
          </div>
      </${Form}>
    </div>
  </${Modal}>`;
};

renderComponent('#workday-checkin-modal', WorkdayCheckinModal);
