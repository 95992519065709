import { Controller } from '@hotwired/stimulus';

// This controller allows to trigger legacy @app/widgets/components/Modal
export class LegacyModalController extends Controller {
  show(event) {
    const { target, payload } = event.params ?? {};
    const customEvent = new CustomEvent('temply.showModal', {
      bubbles: true,
      detail: { id: target, data: payload },
    });

    event.target.dispatchEvent(customEvent);
  }
}
