import { Controller } from '@hotwired/stimulus';
import { i18n } from '@app/i18n';

export class ShiftsController extends Controller {
  static targets = ['mainCheckbox', 'bulkActionMenu', 'counter'];

  static values = {
    date: String,
  };

  changeDate(event) {
    // dispatch submit event to trigger turbo
    const submitEvent = new Event('submit', { bubbles: true });
    event.srcElement.form.dispatchEvent(submitEvent);
  }

  selectAll(event) {
    let isChecked = event.srcElement.checked;

    const inputEvent = new Event('input');
    this.#workdayCheckboxes.forEach((input) => {
      if (input.disabled || input.checked === isChecked) {
        return;
      }

      input.checked = isChecked;
      input.dispatchEvent(inputEvent);
    });

    this.#setBulkActionMenuVisible();
    this.#setCounterText();
  }

  workdaySelectionChanged() {
    let allSelected = true;
    let someSelected = false;

    for (let input of this.#workdayCheckboxes) {
      if (input.disabled) {
        continue;
      }

      if (input.checked) {
        someSelected = true;
      } else {
        allSelected = false;
      }

      if (!allSelected && someSelected) {
        break;
      }
    }

    if (allSelected) {
      this.mainCheckboxTarget.indeterminate = false;
      this.mainCheckboxTarget.checked = true;
    } else if (someSelected) {
      this.mainCheckboxTarget.indeterminate = true;
      this.mainCheckboxTarget.checked = false;
    } else {
      this.mainCheckboxTarget.indeterminate = false;
      this.mainCheckboxTarget.checked = false;
    }

    this.#setBulkActionMenuVisible();
    this.#setCounterText();
  }

  #setBulkActionMenuVisible() {
    const method = this.#numberOfSelectedWorkdays > 0 ? 'remove' : 'add';
    this.bulkActionMenuTarget.classList[method]('hidden');
  }

  get #numberOfSelectedWorkdays() {
    return this.#workdayCheckboxes.filter((input) => input.checked).length;
  }

  #setCounterText() {
    const checkedCount = this.#numberOfSelectedWorkdays;
    const text = i18n.t('approve_shifts.bulk_actions.approve', { count: checkedCount });
    this.counterTarget.innerText = text;
  }

  get #workdayCheckboxes() {
    return [...this.element.querySelectorAll('input[type=checkbox]')]
      .filter((input) => input !== this.mainCheckboxTarget);
  }
}
