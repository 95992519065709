import { tie, onLoad } from '@app/dom';

class CheckboxCollection {
  constructor(element) {
    this.container = element;
    this.inputElement = element.querySelector('.c-input');
    this.optionsContainer = element.querySelector('.c-check-box-collection__options');
    this.optionElements = Array.from(this.optionsContainer.querySelectorAll('.c-check-box-collection__option'));
    this.checkedOptions = new Set();

    onLoad(() => {
      this.bindOptions();
      this.populatePreviewInput();
    });
  }

  onChange = (optionElement, event) => {
    const inputElement = event.target;

    inputElement.checked ? this.checkedOptions.add(optionElement) : this.checkedOptions.delete(optionElement);

    this.populatePreviewInput();
  };

  bindOptions() {
    this.optionElements.forEach((optionElement) => {
      const inputElement = optionElement.querySelector('.c-check-box__input');

      inputElement.checked ? this.checkedOptions.add(optionElement) : this.checkedOptions.delete(optionElement);
      inputElement.addEventListener('change', this.onChange.bind(this, optionElement));
    });
  }

  populatePreviewInput() {
    const texts = Array.from(this.checkedOptions.values()).map(
      (optionElement) => optionElement.querySelector('.c-check-box-collection__option-label').innerText,
    );

    this.inputElement.value = texts.join(', ');
  }
}

tie.bind('.c-check-box-collection', (element) => new CheckboxCollection(element));
