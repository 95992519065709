import { tie, onLoad } from '@app/dom';
import { i18n } from '@app/i18n';

tie.bind('[data-dynamic-form]', async (element) => {
  await onLoad();

  const destroyElement = element.querySelector('[data-dynamic-form-destroy]');

  if (!destroyElement) {
    return;
  }

  destroyElement.addEventListener('click', () => {
    const siblings = element.parentElement.querySelectorAll('[data-dynamic-form]:not(.u-display--none)');

    if (Array.from(siblings).length <= 1) {
      alert(i18n.t('common.error_messages.last_form_item'));
      return;
    }

    const destroyInput = destroyElement.querySelector('input[type="hidden"]');

    element.classList.add('u-display--none');

    if (destroyInput) {
      destroyInput.value = 'true';
    }
  });
});

tie.bind('[data-dynamic-form-add]', (element) => {
  element.addEventListener('click', (event) => {
    event.preventDefault();

    const formAssociationName = element.dataset.dynamicFormAdd;
    const templateElement = element.nextSibling;
    const templateContent = templateElement.content.cloneNode(true);
    const formId = Date.now();
    const formIdPlaceholderRegex = new RegExp(`new_${formAssociationName}`, 'g');
    const fields = templateContent.querySelectorAll('input,textarea,select');

    Array.from(fields).forEach((inputElement) => {
      inputElement.name = inputElement.name.replace(formIdPlaceholderRegex, formId);
    });

    const parentId = element.dataset.dynamicFormAddToId;
    const parent = document.getElementById(parentId);

    parent.appendChild(templateContent);
  });
});
