import { I18n } from 'i18n-js';

import locales from '@app/locales';

export const i18n = new I18n();

i18n.store(locales);
i18n.defaultLocale = 'en';
i18n.enableFallback = true;

// Fetch user locale from html#lang.
// This value is being set on `app/views/layouts/application.html.erb` and
// is inferred from `ACCEPT-LANGUAGE` header.
i18n.locale = document.documentElement.lang;
