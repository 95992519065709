export function parseDate(datePickerInput) {
  if (datePickerInput === null) {
    return null;
  }

  const value = datePickerInput.value;
  if (value === '') {
    return null;
  }

  const format = datePickerInput.dataset.dateFormat || '%Y-%m-%d';

  let formatParts = format.split('-');
  const valueParts = value.split('-');

  if (formatParts.length !== valueParts.length) {
    return null;
  }

  // Workaround for https://github.com/airblade/stimulus-datepicker/issues/33
  // the date has to be first inserted in %Y-%m-%d format or datepicker breaks
  // can be removed when issue is solved, also change default_form_builder
  // and change `formatParts` to a const
  if (parseInt(valueParts[0]) > 1000 && formatParts[0] === '%d') {
    formatParts = ['%Y', '%m', '%d'];
  }

  const formatTuples = formatParts.map((format, index) => {
    return {
      format: format,
      value: parseInt(valueParts[index]),
    };
  });

  let year, month, day;

  for (const formatTuple of formatTuples) {
    switch (formatTuple.format) {
      case '%Y':
        year = formatTuple.value;
        break;
      case '%m':
        month = formatTuple.value - 1;
        break;
      case '%d':
        day = formatTuple.value;
        break;
    }
  }

  return new Date(year, month, day);
}
