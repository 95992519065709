import { useState, useEffect } from 'preact/hooks';
import { html } from 'htm/preact';

import { classNames, tie } from '@app/dom';

export const Modal = ({ isOpen, title, onRequestClose, noBackground, noShadow, children }) => {
  const onOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onRequestClose(event);
    }
  };

  return html`<div className=${classNames('c-modal', isOpen && 'c-modal--open')} aria-hidden="true">
    <div className="c-modal__overlay" tabindex="-1" onClick="${onOverlayClick}">
      <div
        className=${classNames(
          'c-modal__dialog',
          noBackground && 'c-modal__dialog--no-background',
          noShadow && 'c-modal__dialog--no-shadow',
        )}
        role="dialog"
        aria-modal="true"
      >
        ${title
        && html`<div className="c-modal__header">
          <h5 className="c-typography c-typography--header-3 u-margin--none u-text--align-center">${title}</h5>
        </div>`}
        <div className="c-modal__content">${children}</div>
      </div>
    </div>
  </div>`;
};

export const useModal = (parentElement, isOpenDefault = false) => {
  const [isOpen, setIsOpen] = useState(!!isOpenDefault);
  const [triggerData, setTriggerData] = useState({});

  useEffect(() => {
    window.addEventListener('temply.showModal', (event) => {
      if (parentElement.getAttribute('id') === event.detail.id) {
        setIsOpen(true);
        setTriggerData(event.detail.data);
      }
    });
  }, []);

  return [isOpen, setIsOpen, triggerData];
};

tie.bind('[data-show-modal]', (element) => {
  element.addEventListener('click', () => {
    const event = new CustomEvent('temply.showModal', {
      bubbles: true,
      detail: { id: element.dataset.showModal, data: element.dataset },
    });

    element.dispatchEvent(event);
  });
});
