import { tie, onLoad } from '@app/dom';
import { complete } from '@app/scripts/complete';
import { i18n } from '@app/i18n';

class Select {
  constructor(selectElement) {
    this.selectElement = selectElement;
    this.maxSelectedOptions = selectElement.dataset.maxSelectedOptions || Infinity;
    this.maxResults = parseInt(selectElement.dataset.maxResults, 10) || 10;
    this.threshold = parseInt(selectElement.dataset.threshold, 10) || 1;
    this.placeholder = selectElement.dataset.placeholder || '';

    this.createControl();
    onLoad(() => {
      this.populateOptions();
    });
  }

  createControl() {
    const textInput = document.createElement('input');
    textInput.type = 'text';
    textInput.setAttribute('class', 'c-multiselect__input');
    textInput.setAttribute('autocomplete', 'off');
    textInput.setAttribute('placeholder', this.placeholder);

    const autocompleteContainerElement = document.createElement('div');
    autocompleteContainerElement.setAttribute('class', 'c-autocomplete u-size--match-parent');
    autocompleteContainerElement.appendChild(textInput);

    const optionsContainerElement = document.createElement('div');
    optionsContainerElement.setAttribute('class', 'c-multiselect__options');

    const inputContainerElement = document.createElement('div');
    inputContainerElement.setAttribute('class', 'c-multiselect__input-container');
    inputContainerElement.appendChild(autocompleteContainerElement);

    const containerElement = document.createElement('div');
    containerElement.setAttribute('class', 'c-multiselect');
    containerElement.appendChild(inputContainerElement);

    const selectElement = this.selectElement;
    selectElement.classList.add('u-display--none');
    selectElement.parentElement.insertBefore(containerElement, selectElement);

    this.optionsContainerElement = containerElement;
    this.inputContainerElement = inputContainerElement;

    new complete({
      inputSelector: () => textInput,
      maxResults: this.maxResults,
      threshold: this.threshold,
      data: () => ({
        src: async () =>
          Array.from(selectElement.options).map((option) => ({
            label: option.innerText,
            value: option.value,
          })),
        keys: ['label'],
        cache: true,
      }),
      events: {
        input: {
          selection: (event) => {
            const autocompleteSelectionValue = event.detail.selection.value;
            const inputField = event.target;

            this.selectOption(autocompleteSelectionValue.value);

            inputField.value = '';
          },
        },
      },
      noResultsPlaceholder: i18n.t('newdawn.common.no_results'),
    });
  }

  populateOptions() {
    const selectElement = this.selectElement;
    const fragment = document.createDocumentFragment();

    Array.from(selectElement.selectedOptions).forEach((nativeOption) => {
      const option = this.createOption(nativeOption.innerText, nativeOption.value);
      fragment.appendChild(option);
    });

    this.optionsContainerElement.insertBefore(fragment, this.inputContainerElement);
  }

  createOption(label, value) {
    const labelElement = document.createElement('span');
    labelElement.setAttribute('class', 'c-multiselect__label');
    labelElement.innerText = label;

    const iconElement = document.createElement('i');
    iconElement.setAttribute('class', 'fas fa-times');

    const iconContainerElement = document.createElement('span');
    iconContainerElement.setAttribute('class', 'c-multiselect__deselect');
    iconContainerElement.appendChild(iconElement);
    iconContainerElement.addEventListener('click', () => {
      this.optionsContainerElement.removeChild(optionElement);
      this.deselectOption(value);
    });

    const optionElement = document.createElement('div');
    optionElement.setAttribute('class', 'c-multiselect__option');
    optionElement.setAttribute('data-value', value);
    optionElement.appendChild(labelElement);
    optionElement.appendChild(iconContainerElement);

    return optionElement;
  }

  getNativeOptionForValue(value) {
    return Array.from(this.selectElement.options).find((option) => option.value === String(value));
  }

  countSelected() {
    return Array.from(this.selectElement.options).filter((option) => option.selected).length;
  }

  selectOption(value) {
    if (this.countSelected() >= this.maxSelectedOptions) {
      return false;
    }

    const nativeOption = this.getNativeOptionForValue(value);

    if (nativeOption && !nativeOption.selected) {
      nativeOption.selected = true;

      const option = this.createOption(nativeOption.innerText, nativeOption.value);
      this.optionsContainerElement.insertBefore(option, this.inputContainerElement);

      return true;
    }

    return false;
  }

  deselectOption(value) {
    const nativeOption = this.getNativeOptionForValue(value);

    if (nativeOption) {
      nativeOption.selected = false;
    }
  }
}

tie.bind('select[data-multiselect]', (element) => new Select(element));
