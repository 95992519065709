import { tie, onLoad } from '@app/dom';

class RatingStars {
  constructor(element) {
    this.icons = Array.from(element.querySelectorAll('i'));
    this.input = null;

    element.addEventListener('mouseover', this.onHover);
    element.addEventListener('click', this.onClick);
    element.addEventListener('mouseleave', this.onMouseLeave);

    onLoad(() => {
      this.input = element.nextElementSibling;
      this.onMouseLeave(this.input.value);
    });
  }

  setStars(index) {
    this.icons.forEach((icon, i) => {
      const classNames = i <= index ? 'fas fa-star' : 'far fa-star';

      icon.setAttribute('class', classNames);
    });
  }

  onHover = (event) => {
    const index = this.icons.indexOf(event.target);

    if (index === -1) {
      return;
    }

    this.setStars(index);
  };

  onClick = (event) => {
    const index = this.icons.indexOf(event.target);

    if (index === -1) {
      return;
    }

    this.setStars(index);
    this.input.value = index + 1;
  };

  onMouseLeave = () => {
    this.setStars(parseInt(this.input.value, 10) - 1);
  };
}

tie.bindClass('.c-rating-stars[data-interactive]', RatingStars);
