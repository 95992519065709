import { Controller } from '@hotwired/stimulus';

export class RemoteModal extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.element.close();
      }
    });
  }
}
