const parseEnvironment = () => {
  const data = sessionStorage.getItem('temply');

  try {
    return data ? JSON.parse(data) : {};
  } catch (error) {
    return {};
  }
};

export const environment = new Proxy(parseEnvironment(), {
  get(obj, prop) {
    if (prop in obj) {
      return obj[prop];
    }

    throw new Error(`Environment does not contain a property named "${prop}"`);
  },
});

export default environment;
