import { Controller } from '@hotwired/stimulus';

export class LineClampController extends Controller {
  static targets = ['clamp', 'button'];

  connect() {
    const shouldShowReadMore = (this.clampTarget.scrollHeight > this.clampTarget.clientHeight);
    this.buttonTarget.classList.toggle('hidden', !shouldShowReadMore);
  }

  showAll() {
    Array.from(this.clampTarget.classList)
      .filter((cssClass) => cssClass.startsWith('line-clamp-'))
      .forEach((cssClass) => this.clampTarget.classList.remove(cssClass));
    this.buttonTarget.remove();
  }
}
