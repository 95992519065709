import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';
import '@app/scripts';
import '@app/widgets'; // Import preact components
import '@app/controllers'; // Import stimulus controllers
import '@app/stream_actions'; // Import custom turbo stream actions

// Google Maps API requires a defined callback, so import it after the app
import 'google-maps-api';

// Disable Turbo Drive for all links by default
Turbo.session.drive = false;
Rails.start();
