import { Controller } from '@hotwired/stimulus';

export class DatepickerNavigationController extends Controller {
  static values = {
    placeholder: { type: String, default: ':XYZ' },
    urlFormat: { type: String },
  };

  connect() {
    this.element.addEventListener('change', (event) => {
      const url = this.urlFormatValue.replace(this.placeholderValue, event.target.value);
      window.Turbo.visit(url);
    });
  }
}
