import { h, render } from 'preact';
import { Necktie } from '@lesniewski.pro/necktie';

export const tie = new Necktie().startListening();

export function classNames(...values) {
  const classes = values.reduce((names, value) => {
    return typeof value === 'string' ? [...names, value] : names;
  }, []);

  return classes.join(' ');
}

export function onLoad(callback) {
  if (typeof callback !== 'function') {
    return new Promise((resolve) => onLoad(resolve));
  }

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    callback();
  }
}

export function renderComponent(selector, component) {
  tie.bind(selector, (element) => {
    render(h(component, { parentElement: element }), element);

    return (removedElement) => {
      render(null, removedElement);
    };
  });
}

export function defer(callback) {
  if (typeof callback === 'function') {
    return setTimeout(callback, 0);
  }

  return null;
}
