import { tie, onLoad } from '@app/dom';

const buildIdFromName = (name) => name.replace(/[[\]]/g, '_').replace(/[_]+/g, '_');

class ScheduleCheckboxField {
  constructor(element) {
    this.checkBoxField = element;

    const data = this.checkBoxField.dataset;

    this.startTimeField = document.querySelector(buildIdFromName(`#${data.objectName}_${data.startTimeField}`));
    this.endTimeField = document.querySelector(buildIdFromName(`#${data.objectName}_${data.endTimeField}`));

    this.generalCheckboxField = document.querySelector(
      `[data-general-schedule-checkbox-day="${data.scheduleCheckboxDay}"]`,
    );
    this.generalStartTimeField = document.querySelector(`#offer_hour_from-minute_from`);
    this.generalEndTimeField = document.querySelector(`#offer_hour_to-minute_to`);

    this.bind();
  }

  enableTimeFields = () => {
    this.startTimeField.disabled = !this.checkBoxField.checked;
    this.endTimeField.disabled = !this.checkBoxField.checked;
    this.generalCheckboxField.checked = this.checkBoxField.checked;
  };

  copyTimeValues = () => {
    this.startTimeField.value = this.checkBoxField.checked ? this.generalStartTimeField.value : '';
    this.endTimeField.value = this.checkBoxField.checked ? this.generalEndTimeField.value : '';

    this.startTimeField.dispatchEvent(new Event('change'));
    this.endTimeField.dispatchEvent(new Event('change'));
  };

  onChange = () => {
    this.enableTimeFields();
    this.copyTimeValues();
  };

  bind() {
    this.checkBoxField.addEventListener('change', this.onChange);
    this.enableTimeFields();
  }
}

class GeneralScheduleCheckboxField {
  constructor(element) {
    this.generalCheckBoxField = element;

    const data = this.generalCheckBoxField.dataset;

    this.scheduleCheckboxField = document.querySelector(
      `[data-schedule-checkbox-day="${data.generalScheduleCheckboxDay}"]`,
    );

    this.bind();
  }

  enableCheckboxes = () => {
    this.scheduleCheckboxField.checked = this.generalCheckBoxField.checked;
    this.scheduleCheckboxField.dispatchEvent(new Event('change'));
  };

  bind() {
    this.generalCheckBoxField.addEventListener('change', this.enableCheckboxes);
  }
}

class GeneralTimeField {
  constructor(element) {
    this.generalTimeField = element;

    const data = this.generalTimeField.dataset;

    this.timeFields = document.querySelectorAll(`.${data.timeFieldClass}`);

    this.bind();
  }

  copyValue = () => {
    for (const field of this.timeFields) {
      if (!field.disabled) {
        field.value = this.generalTimeField.value;
        field.dispatchEvent(new Event('change'));
      }
    }
  };

  bind() {
    this.generalTimeField.addEventListener('change', this.copyValue);
  }
}

onLoad(() => {
  tie.bind('.schedule-checkbox', (element) => new ScheduleCheckboxField(element));
  tie.bind('.general-schedule-checkbox', (element) => new GeneralScheduleCheckboxField(element));
  tie.bind('.general-schedule-time-field', (element) => new GeneralTimeField(element));
});
