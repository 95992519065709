import { Controller } from '@hotwired/stimulus';
import { i18n } from '@app/i18n';

export const DisableReason = {
  OutsideOfferDates: 'outside_offer_dates',
  InOtherSchedule: 'in_other_schedule',
};

const ErrorType = {
  Weekdays: 'weekdays',
};

export class OfferScheduleController extends Controller {
  static targets = [
    'day', 'dayInput', 'delete', 'breakInput', 'shiftStart', 'shiftEnd', 'tooltip', 'errors',
  ];

  static values = {
    selectedClass: { type: String, default: 'x-button--primary' },
    hiddenClass: { type: String, default: 'hidden' },
    errorClass: { type: String, default: 'x-input--error' },
  };

  #errors = new Map();

  get #selectedDays() {
    return this.dayInputTargets.map((input, index) => {
      if (input.value === '1') {
        return index;
      } else {
        return null;
      }
    }).filter((x) => x !== null);
  }

  set #selectedDays(selectedDays) {
    this.dayInputTargets.forEach((input) => input.value = '');
    selectedDays.forEach((selectedDay) => this.dayInputTargets[selectedDay].value = '1');
  }

  // break is a keyword so I'm calling this siesta :D
  #siesta = null;

  get selectedDays() {
    return this.#selectedDays;
  }

  get deletingEnabled() {
    return this.deleteTarget.classList.contains(this.hiddenClassValue);
  }

  set deletingEnabled(enabled) {
    if (enabled) {
      this.deleteTarget.classList.remove(this.hiddenClassValue);
    } else {
      this.deleteTarget.classList.add(this.hiddenClassValue);
    }
  }

  connect() {
    let selectedDays = [];
    this.dayInputTargets.forEach((input, index) => {
      if (input.value !== '1') {
        return;
      }

      selectedDays.push(index);
    });

    this.selectDays(selectedDays);
  }

  delete(event) {
    event.preventDefault();
    this.element.remove();
  }

  enableAllDays() {
    for (const dayElement of this.dayTargets) {
      dayElement.disabled = false;
      dayElement.dataset.controller = '';
    }

    for (const tooltipElement of this.tooltipTargets) {
      tooltipElement.innerHTML = '';
      tooltipElement.classList.add('hidden');
    }
  }

  selectDays(days) {
    this.#selectedDays = days;

    days.map((day) => this.dayTargets[day])
      .forEach((dayElement) => dayElement.classList.add(this.selectedClassValue));
  }

  disableDays(days, reason) {
    const localeNamespace = 'new_offer_form.schedule.disable_reason';
    for (const day of days) {
      const dayElement = this.dayTargets[day];

      if (dayElement.disabled) {
        continue;
      }

      dayElement.classList.remove(this.selectedClassValue);
      dayElement.disabled = true;

      const tooltipElement = this.tooltipTargets[day];
      tooltipElement.innerHTML = i18n.t(`${localeNamespace}.${reason}`);
      dayElement.dataset.controller = 'toggle';

      this.#deselectDay(day);
    }
  }

  #deselectDay(day) {
    this.#selectedDays = this.#selectedDays.filter((selectedDay) => selectedDay != day);
  }

  #selectDay(day) {
    this.#selectedDays = [...this.#selectedDays, day];
  }

  toggleDay(event) {
    event.preventDefault();

    const day = this.dayTargets.indexOf(event.srcElement);
    const index = this.#selectedDays.indexOf(day);
    if (index === -1) {
      this.#selectDay(day);
      event.srcElement.classList.add(this.selectedClassValue);
    } else {
      this.#deselectDay(day);
      event.srcElement.classList.remove(this.selectedClassValue);
    }

    this.dispatch('days-changed');
  }

  breakUpdated(event) {
    this.#siesta = event.detail.break;

    if (event.detail.userInitiated) {
      this.validate();
    }
  }

  validate() {
    const isBreakValid = this.#validateBreak();
    this.breakInputTarget.classList.toggle(this.errorClassValue, !isBreakValid);

    const areWeekdaysValid = this.#validateWeekdays();
    if (!areWeekdaysValid) {
      const message = i18n.t('new_offer_form.schedule.error.weekdays.blank');
      this.#addError(ErrorType.Weekdays, message);
    } else {
      this.#removeError(ErrorType.Weekdays);
    }

    this.#presentErrors();

    return isBreakValid && areWeekdaysValid;
  }

  #validateBreak() {
    if (this.#siesta === null) {
      return true;
    }

    const shiftStart = this.shiftStartTarget.value;
    const shiftEnd = this.shiftEndTarget.value;

    if (shiftStart === '' || shiftEnd === '') {
      return true;
    }

    let shift = this.#parseValues(shiftStart, shiftEnd);
    let siesta = this.#parseValues(this.#siesta.start, this.#siesta.end);

    return (siesta.start >= shift.start) && (siesta.end <= shift.end);
  }

  #validateWeekdays() {
    return this.#selectedDays.length > 0;
  }

  #timeValueToTimestamp(value) {
    const splitted = value.split(':');
    return parseInt(splitted[0]) * 60 + parseInt(splitted[1]);
  }

  #parseValues(startValue, endValue) {
    const start = this.#timeValueToTimestamp(startValue);
    let end = this.#timeValueToTimestamp(endValue);

    if (start > end) {
      end += 24 * 60;
    }

    return { start: start, end: end };
  }

  #addError(errorType, message) {
    this.#errors.set(errorType, message);
  }

  #removeError(errorType) {
    this.#errors.delete(errorType);
  }

  #presentErrors() {
    this.errorsTarget.classList.toggle(this.hiddenClassValue, this.#errors.size === 0);
    this.errorsTarget.innerHTML = '';

    for (const message of this.#errors.values()) {
      const icon = document.createElement('i');
      icon.classList.add('fa-solid', 'fa-triangle-exclamation', 'mr-1');

      const text = document.createElement('span');
      text.textContent = message;

      const div = document.createElement('div');
      div.appendChild(icon);
      div.appendChild(text);

      this.errorsTarget.appendChild(div);
    }
  }
}
